<div class="hero-image">
    <div class="hero-text">
      <h1>Wij helpen het internet voor iedereen toegankelijk te maken</h1>
      <!--p>om het internet toegankelijk en begrijpelijk te maken voor mensen voor wie dit niet vanzelfsprekend is.</p>
      <p>om informatie op het internet voor iedereen begrijpelijk te maken.</p>
      <p>om iedereen te helpen informatie op waarde te schatten.</p -->
      <!-- p>Hoe? .</p -->
    <!-- <button>Find out more</button>--> 
    </div>
    <div class='hero-arrow'>
        <mat-icon class='arrow' inline=true (click)="scroll(target)">keyboard_arrow_down</mat-icon>
    </div>
  </div>
  <div #target><app-featured>


  </app-featured></div>
