import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-country-code-select',
  templateUrl: './country-code-select.component.html',
  styleUrls: ['./country-code-select.component.scss']
})
export class CountryCodeSelectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
