import { LoaderService } from './../loader/loader.service';
import { Component, OnInit } from '@angular/core';
import Amplify, { API } from 'aws-amplify';
import { Observable, of, Subscription, from } from 'rxjs';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { AmplifyService } from 'aws-amplify-angular';
import { DomSanitizer } from '@angular/platform-browser';
import { type } from 'node:os';
import Auth from '@aws-amplify/auth';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-tweet-annotator',
  templateUrl: './tweet-annotator.component.html',
  styleUrls: ['./tweet-annotator.component.scss']
})
export class TweetAnnotatorComponent implements OnInit {
  topics: FormGroup;

  
  twitterList;
  twitterClaimList;
  currentClaim;
  currentTweet;
  index = 0;
  indexClaim=0;
  userMail="";
  url:any;
  FCTweeturl:any;
  factCheckUrl:any;
  factCheckUrlToLink:any;

  constructor(private amplifyService: AmplifyService, private _loader: LoaderService, public sanitizer: DomSanitizer, fb:FormBuilder) {
    this.topics = fb.group({
      veiligheid: false,
      effectiviteit: false,
      noodzaak: false,
      wantrouwen_complot: false,
      anders: false
    });



   }


  
  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
  
  ngOnInit(): void {
    this._loader.show("loading tweets");
    Auth.currentUserInfo()
    .then((data) => {
      console.log(data.attributes.email);
      this.userMail = data.attributes.email;

      this.getSearchresults().subscribe(
          results => {console.log(results);
          //this.twitterList=results.Items

          //this.index = this.getRandomInt(results.Items.length);
            
          this.currentTweet = results;

          this.currentTweet.relevant=0;
          this.getUrl();
          this._loader.hide();

        }
      )
       // this data has user details in accessToken
    }).catch(err => console.log(err));

    Auth.currentUserInfo()
    .then((data) => {
      console.log(data.attributes.email);
      this.userMail = data.attributes.email;

      this.getFactchecks().subscribe(
          results => {console.log(results);
          //this.twitterList=results.Items

          //this.index = this.getRandomInt(results.Items.length);
            
          this.currentClaim = results;
          this.getFCUrl();

          this._loader.hide();

        }
      )
       // this data has user details in accessToken
    }).catch(err => console.log(err));
  }  
  
  getSearchresults(): Observable<any>{
    //API.cancel(this.promise, "my error message");
    return from(this.makeAPICall({"type":"", "username": this.userMail})).pipe(
      map(result => result = result.data));
  }

  getFactchecks(): Observable<any>{
    //API.cancel(this.promise, "my error message");
    return from(this.makeFactcheckAPICall({"username": this.userMail})).pipe(
      map(result => result = result.data));
  }

  makeAPICall(_queryStringParameters) {
    let options = { // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters:_queryStringParameters,
    };
    //this.previousPromise = API.get("aiportalapi", "/autocomplete",options);
    return API.get("miaapi", "/tweets",options);
  }

  makeFactcheckAPICall(_queryStringParameters) {
    let options = { // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters:_queryStringParameters,
    };
    //this.previousPromise = API.get("aiportalapi", "/autocomplete",options);
    return API.get("miaapi", "/factcheck",options);
  }

  makeClaimAPICall(_queryStringParameters) {
    let options = { // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters:_queryStringParameters,
    };
    //this.previousPromise = API.get("aiportalapi", "/autocomplete",options);
    return API.get("miaapi", "/factchecks",options);
  }

  vote(vt, _type){
    if(_type=='tweet'){
      this._loader.show("antwoord wordt verstuurd")
      let options = { // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        queryStringParameters: {  // OPTIONAL
            tweetid: this.currentTweet["tweetid"],
            date: this.currentTweet["date"],
            vote: vt,
            type: _type
        },
      };
      return API.get("miaapi", "/tweet/update",options).then((result) =>
        {        
          console.log(this.index);
          if(this.index==this.twitterList.length)
          {
              this.index = this.getRandomInt(this.twitterList.Items.length);
          }
          this.currentTweet = this.twitterList[this.index++];

          //console.log("https://twitter.com/i/web/status/"+this.currentTweet.tweetid);
          //this.currentTweet.embedUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://twitter.com/i/web/status/"+this.currentTweet.tweetid);
          //console.log(this.currentTweet.embedUrl);
          this._loader.hide()

        }
      )
    }
    else if(_type=='claim'){
      this._loader.show("antwoord wordt verstuurd")
      let options = { // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        queryStringParameters: {  // OPTIONAL
            tweetid: this.currentClaim["tweetid"],
            date: this.currentClaim["date"],
            vote: vt,
            type: _type
        },
      };
      return API.get("miaapi", "/tweet/update",options).then((result) =>
        {        
          console.log(this.indexClaim);
          if(this.indexClaim==this.twitterClaimList.length)
          {
              this.indexClaim = this.getRandomInt(this.twitterClaimList.Items.length);
          }
          this.currentClaim = this.twitterClaimList[this.indexClaim++];

          //console.log("https://twitter.com/i/web/status/"+this.currentTweet.tweetid);
          //this.currentTweet.embedUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://twitter.com/i/web/status/"+this.currentTweet.tweetid);
          //console.log(this.currentTweet.embedUrl);
          this._loader.hide()
        }
      )

    }
  }

  onItemChange(event, tp){
    switch(tp){
      case "relevant": {
        this.currentTweet.relevant=event.value;

      }
      case "sentiment": {
        this.currentTweet.sentiment=event.value;


      }

    }
 

 }

 stopProp(event)
 {
  event.stopPropagation();


 }

  getSafeIFrameUrl()
  {
    

  }

  getUrl()
  {
   //#region  console.log("getUrl called");
    this.url= this.sanitizer.bypassSecurityTrustResourceUrl("https://lftmijv57l.execute-api.eu-west-1.amazonaws.com/mia/embedtweet/"+this.currentTweet?.tweetid);
    

  }

  getFCUrl()
  {
   //#region  console.log("getUrl called");
    this.FCTweeturl= this.sanitizer.bypassSecurityTrustResourceUrl("https://lftmijv57l.execute-api.eu-west-1.amazonaws.com/mia/embedtweet/"+this.currentClaim?.tweet_id);
    this.factCheckUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentClaim?.url);
    this.factCheckUrlToLink=this.currentClaim?.url
  }

 

  getClaims(){
    return from(this.makeAPICall({"type":"claim"})).pipe(
      map(result => result = result.data));

  }


  fetchtweets(){
      this._loader.show("nieuwe tweets worden geladen")
      let options = { // OPTIONAL
        headers: {}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        queryStringParameters: {  // OPTIONAL
        },
      };
      return API.get("miaapi", "/fetchnewtweets",options).then((result) =>
        {        
          console.log(this.index);
          /*if(this.index==this.twitterList.length)
          {
              this.index = this.getRandomInt(this.twitterList.Items.length);
          }
          this.currentTweet = this.twitterList[this.index++];*/
          console.log('new tweets loaded to db');

          //console.log("https://twitter.com/i/web/status/"+this.currentTweet.tweetid);
          //this.currentTweet.embedUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://twitter.com/i/web/status/"+this.currentTweet.tweetid);
          //console.log(this.currentTweet.embedUrl);
          this._loader.hide()

        }
      )
    
  }


  save(){
    console.log(this.topics.value);
    console.log(this.currentTweet);
    
    this.currentTweet.onderwerp_veiligheid = this.topics.value.veiligheid?1:0;
    this.currentTweet.onderwerp_effectiviteit = this.topics.value.effectiviteit?1:0;
    this.currentTweet.onderwerp_noodzaak = this.topics.value.noodzaak?1:0;
    this.currentTweet.onderwerp_wantrouwen_complot = this.topics.value.wantrouwen_complot?1:0;
    this.currentTweet.onderwerp_anders = this.topics.value.anders?1:0;
    this.currentTweet.name=this.userMail;

    this.topics.reset();


    this._loader.show("antwoord wordt verstuurd")
    let options = { // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {  // OPTIONAL
          tweetid: this.currentTweet["tweetid"],
          date: this.currentTweet["date"],
          username: this.currentTweet.name,
          relevant: this.currentTweet.relevant,
          sentiment: this.currentTweet.sentiment,
          onderwerp_veiligheid:  this.currentTweet.onderwerp_veiligheid,
          onderwerp_effectiviteit: this.currentTweet.onderwerp_effectiviteit,
          onderwerp_noodzaak: this.currentTweet.onderwerp_noodzaak,
          onderwerp_wantrouwen_complot: this.currentTweet.onderwerp_wantrouwen_complot,
          onderwerp_anders: this.currentTweet.onderwerp_anders
        },
    };
    API.get("miaapi", "/tweet/update",options).then((result) =>
      {        
        this.getSearchresults().subscribe(
          results => {console.log(results);
          //this.twitterList=results.Items
    
          //this.index = this.getRandomInt(results.Items.length);
            
              
    
          this.currentTweet = results;
    
          this.currentTweet.relevant=0;
          this.getUrl();

          this._loader.hide();
        });
      });
   
  }

  saveFC(){
    this._loader.show("antwoord wordt verstuurd")
    let options = { // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {  // OPTIONAL
          tweetid: this.currentClaim["tweet_id"],
          factcheckid:this.currentClaim["idfactcheck"],
          relevant: this.currentClaim.relevant,
          username: this.userMail
       },
    };
    API.get("miaapi", "/factcheck/update",options).then((result) =>
      {        
        console.log(result);
        this.getFactchecks().subscribe(
          results => {console.log(results);
          this.currentClaim = results;
    
          this.currentClaim.relevant=0;
          this.getFCUrl();

          this._loader.hide();
        });
      });
   
  }
}