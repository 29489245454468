import { MaterialModule } from './material/material/material.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationComponent } from './navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import {FlexLayoutModule} from '@angular/flex-layout';
import { MIADemoComponent } from './miademo/miademo.component';
import { AboutComponent } from './about/about.component';
import { ReaderComponent } from './reader/reader.component';
import { ReferencesComponent } from './references/references.component';
import {AmplifyUIAngularModule} from "@aws-amplify/ui-angular";
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular'
import {Amplify} from "aws-amplify";
import awsconfig from "../aws-exports";
import { AuthComponent } from './auth/auth.component';
import { LoaderComponent } from './loader/loader.component';
import { CountryCodeSelectComponent } from './auth/country-code-select/country-code-select.component';
import { FilterPipe } from './auth/country-code-select/filter.pipe';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { ConfirmCodeComponent } from './auth/confirm-code/confirm-code.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { AvatarComponent } from './auth/profile/avatar/avatar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TweetAnnotatorComponent } from './tweet-annotator/tweet-annotator.component';
import { HomeComponent } from './home/home.component';
import { HeroComponent } from './hero/hero.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { FeaturedComponent } from './featured/featured.component';
import { NewsComponent } from './news/news.component';
import { DemoComponent } from './demo/demo.component';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { SafePipe } from './safe.pipe';

Amplify.configure(awsconfig);

const cookieConfig:NgcCookieConsentConfig = {
  
    "cookie": {
      "domain": "miahelpt.nl"
    },
    "position": "bottom",
    "theme": "classic",
    "palette": {
      "popup": {
        "background": "#000000",
        "text": "#ffffff",
        "link": "#ffffff"
      },
      "button": {
        "background": "#f1d600",
        "text": "#000000",
        "border": "transparent"
      }
    },
    "type": "opt-in",
    "content": {
      "message": "Deze website gebruikt cookies om onze website goed te laten werken en om onze diensten te verbeteren. We gebruiken geen tracking cookies.",
      "dismiss": "Weiger cookies",
      "deny": "Weiger",
      "allow": "Toestaan",
      "link": "Wat zijn cookies?",
      "href": "https://veiliginternetten.nl/themes/situatie/wat-zijn-cookies/",
      "policy": "Cookie Policy"
  }
  
};


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    DashboardComponent,
    MIADemoComponent,
    AboutComponent,
    ReaderComponent,
    ReferencesComponent,
    AuthComponent,
    LoaderComponent,
    CountryCodeSelectComponent,
    FilterPipe,
    SignInComponent,
    SignUpComponent,
    ConfirmCodeComponent,
    ProfileComponent,
    AvatarComponent,
    TweetAnnotatorComponent,
    HomeComponent,
    HeroComponent,
    NavComponent,
    FooterComponent,
    FeaturedComponent,
    NewsComponent,
    DemoComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    FlexLayoutModule,
    AmplifyUIAngularModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgcCookieConsentModule.forRoot(cookieConfig)


  ],
  providers: [AmplifyService],
  bootstrap: [AppComponent],
  entryComponents: [LoaderComponent, CountryCodeSelectComponent]
})
export class AppModule { }
