<div class="main-container unicorn-light-theme">
    <section id="timeline" class="timeline-outer">
      <div class="container" id="content">
        <div class="row">
          <div class="col s12 m12 l12">
            <!--h1>Mia: Een decennium onderweg naar een inclusief internet</h1 -->
<!--
              <mat-card>
                <mat-card-title>Over Mia</mat-card-title>
                <mat-card-subtitle>Wie of wat is Mia en wat doet zij?</mat-card-subtitle>

                <mat-card-content>	
                    <ul class="timeline">
                        <li class="event" data-date="">
                            <h3>Wat</h3>

                            <p>Mia, <i>mijn internet assistent</i>, heeft als doel digitale informatie beter toegankelijk te maken voor iedereen.</p>

                        </li>
                        <li class="event" data-date="">
                            <h3>We</h3>
                            <p>In Stichting Mia helpt wordt software ontwikkeld, samen met partners, om deze doelstelling mogelijk te maken.</p>
                        </li>
                        <li class="event" data-date="">
                            <h3>Doen</h3>

                            <p>Mia ontwikkelt onder andere een digitale assistent en een web-voorleesfunctie. </p>

                        </li>

                      </ul>
                </mat-card-content>	
    </mat-card>
    <br>-->
        <mat-card>
            <mat-card-content>	
                <mat-card-title>Korte tijdslijn</mat-card-title>
                <mat-card-subtitle>Een decennium onderweg naar een inclusief internet</mat-card-subtitle>

            <ul class="timeline">
              <li class="event" data-date="2010">
                <h3>Mia wordt geboren</h3>
                <p>
                    In 2010 werd Mia tijdens IBMs Extreme Blue progamma ontwikkeld door Maarten-Pieter Lantsheer, Jasmijn Bastings, Alexandra Caraghiulea en Antonio Alonso Daroca, in opdracht van ECP-EPN. 
                </p>
              </li>
              <li class="event" data-date="2012">
                <h3>Mia op eigen benen</h3>
                <p>
                    In 2012 is Mia overgedragen door IBM en ECP-EPN aan Stichting Mia Helpt, opgericht door Maarten-Pieter Lantsheer en Robert-Jan Sips, met als doelstelling Mia zelfstandig in de markt te zetten.               
                </p>
              <li class="event" data-date="2012-2018">
                <h3>Mia helpt</h3>
                <p>In de jaren 2012 tot 2017 wordt Mia ingezet bij diverse overheidsinstanties, om mensen met minder digitale vaardigheden te helpen met toegang te krijgen tot een beter internet.
                </p>
              </li>
              <li class="event" data-date="2018-heden">
                <h3>Mia werkt</h3>
                <p>Vanaf 2018 wordt Mia door de vrijwilligers van de stichting omgebouwd in een nieuwe versie van Web Avatar Mia, een aparte voorleestool (webreader) en wordt er onderzoek gestart naar het automatisch toegankelijk maken van taal.</p>
              </li>
            </ul>
        </mat-card-content>	

        </mat-card>

          </div>
        </div>
      </div>
    </section>
    <br/>
  </div>