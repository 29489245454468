import {
  Component,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy
} from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { MatSidenav } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "./auth/auth.service";
import Auth from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription }   from 'rxjs';
import { AnalyticsService } from "./analytics.service";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [AnalyticsService]
})
export class AppComponent implements OnInit, OnDestroy {
    //keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription!: Subscription;
    private popupCloseSubscription!: Subscription;
    private initializingSubscription!: Subscription;
    private initializedSubscription!: Subscription;
    private initializationErrorSubscription!: Subscription;
    private statusChangeSubscription!: Subscription;
    private revokeChoiceSubscription!: Subscription;
    private noCookieLawSubscription!: Subscription;
  


  title = "Material PWA";
  mobileQuery: MediaQueryList;
  nav = [
    {
      title: "Home",
      path: "/"
    },
    {
      title: "My Account (Part 2)",
      path: "/auth/signin"
    }
  ];
  avatar: string;
  private _mobileQueryListener: () => void;
  @Output() toggleSideNav = new EventEmitter();

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public auth: AuthService,
    private toast: MatSnackBar,
    private ccService: NgcCookieConsentService, 
    private analyticsService: AnalyticsService
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    auth.authState.subscribe((event: string) => {
      if (event === AuthService.SIGN_IN) this.checkSession();
      if (event === AuthService.SIGN_OUT) this.avatar = undefined;
    });
  }

  ngOnInit() {
    this.detectIOS();
    this.checkSession();
    if(this.ccService.hasConsented())
    {
      this.analyticsService.startTracking();


    }
    // subscribe to cookieconsent observables to react to main events
      this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
        () => {
          console.log("popup open");
          // you can use this.ccService.getConfig() to do stuff...
        });
  
      this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
        () => {
          // you can use this.ccService.getConfig() to do stuff...
          console.log("popup closed");

        });
  
      /*this.initializingSubscription = this.ccService.initializing$.subscribe(
        (event: NgcInitializingEvent) => {
          // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
          console.log(`initializing: ${JSON.stringify(event)}`);
        }); */
      
      this.initializedSubscription = this.ccService.initialize$.subscribe(
        (event:any) => {
          // the cookieconsent has been successfully initialized.
          // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
          console.log(`initialized: ${JSON.stringify(event)}`);
        });/*
  
      this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
        (event: NgcInitializationErrorEvent) => {
          // the cookieconsent has failed to initialize... 
          console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
        });
  
      this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        (event: NgcStatusChangeEvent) => {
          // you can use this.ccService.getConfig() to do stuff...
        });
        
      this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        () => {
          // you can use this.ccService.getConfig() to do stuff...
        });
  
        this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        (event: NgcNoCookieLawEvent) => {
          // you can use this.ccService.getConfig() to do stuff...
        });*/

        this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
          () => {
            // you can use this.ccService.getConfig() to do stuff...
          });
    
          this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
          () => {
            console.log("no consent")
            // you can use this.ccService.getConfig() to do stuff...
          });

          this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
            (event: any) => {
              // you can use this.ccService.getConfig() to do stuff...
              console.log(event.status);
              if(event.status=="allow")
              {
                this.analyticsService.startTracking();

              }
              else
              {


              }
            });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  async checkSession() {
    try {
      const userInfo = await Auth.currentUserInfo();
      if (userInfo && userInfo.attributes.profile) {
        const avatar = userInfo.attributes.profile;
        const url = (await Storage.vault.get(avatar)) as string;
        this.avatar = url;
      }
    } catch (error) {
      console.log("no session: ", error);
    }
  }

  detectIOS() {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      "standalone" in (window as any).navigator &&
      (window as any).navigator.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      setTimeout(() => {
        /*this.toast.openFromComponent(IosInstallComponent, {
          duration: 8000,
          horizontalPosition: "start",
          panelClass: ["mat-elevation-z3"]
        });*/
      });
    }
  }

  toggleMobileNav(nav: MatSidenav) {
    if (this.mobileQuery.matches) {
      nav.toggle();
    }
  }
}