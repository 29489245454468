/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:e5a5a0a9-4299-40ba-9280-8366052d1221",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_KngUcNg2m",
    "aws_user_pools_web_client_id": "55c0pfd1eh7ngr1unf9of3vluh",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "miaapi",
            "endpoint": "https://lftmijv57l.execute-api.eu-west-1.amazonaws.com/mia",
            "region": "eu-west-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "tweets-mia",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "miafiles215355-mia",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
