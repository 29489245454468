import { TweetAnnotatorComponent } from './tweet-annotator/tweet-annotator.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { ConfirmCodeComponent } from './auth/confirm-code/confirm-code.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { AuthComponent } from './auth/auth.component';
import { UnauthGuard } from './auth/unauth.guard';
import { AuthGuard } from './auth/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { MIADemoComponent } from './miademo/miademo.component';
import { ReaderComponent } from './reader/reader.component';
import { ReferencesComponent } from './references/references.component';
import { HomeComponent } from './home/home.component';
import { DemoComponent } from './demo/demo.component';

const routes: Routes = [
  { path: 'auth', component: AuthComponent, children: [    
    {      
       path: 'signin',      
       component: SignInComponent,      
       canActivate: [UnauthGuard]    
    },    
    {      
       path: 'signup',
       component: SignUpComponent,
       canActivate: [UnauthGuard]    
    },    
    {
       path: 'confirm',
       component: ConfirmCodeComponent,
       canActivate: [UnauthGuard]    
    },    
    {
       path: 'profile',
       component: ProfileComponent,
       canActivate: [AuthGuard]
    }  
 ]},  
  {  path: '', component: HomeComponent, pathMatch: 'full' },
  {  path: 'about', component: AboutComponent },
  {  path: 'factchecker', component: DemoComponent },

   { path: 'mia', component: MIADemoComponent},
   { path: 'reader', component: ReaderComponent,   canActivate: [AuthGuard] },
   { path: 'references', component: ReferencesComponent},
   { path: 'tweets', component: TweetAnnotatorComponent,  canActivate: [AuthGuard]},
   { path: 'home', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
