<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [hidden]="!(isHandset$ | async)"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="false">
      <!-- [opened]="(isHandset$ | async) === false" -->
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <img alt="MIA logo" src="/assets/images/MIA_circle.png" width=100px />
    </div>
    <mat-nav-list>
      <a mat-list-item [routerLink]="['/']">Home</a>
      <a mat-list-item [routerLink]="['/about']">Over</a>
      <a mat-list-item [routerLink]="['/demos']">Demos</a>

      <!-- a mat-list-item href="mia">Ontmoet MIA</a>
      <a mat-list-item href="reader">Web reader</a>
      <a mat-list-item href="references">Referenties</a>
      <a mat-list-item [routerLink]="['/tweets']">Tweet annotation</a -->

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="unicorn-dark-theme" >
    <mat-toolbar color="primary" >
      <button
        *ngIf="isHandset$ | async"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"        >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
        <span class="span"> <img alt="MIA logo" src="/assets/images/MIA_circle.png" width=50px />
        </span> <a [routerLink]="['/']" style="text-decoration: none; color:white; line-height:42px;">Stichting Mia Helpt</a>
        <div class='links' [hidden]="(isHandset$ | async)">
          <ul class="navlist">
          <li><a [routerLink]="['/about']">Over</a></li>
          <li><a [routerLink]="['/factchecker']">Factchecker</a></li>
          <!-- li><a [routerLink]="['/tweets']">Annoteren</a></li -->

          <!--li><a><mat-icon>account_circle</mat-icon></a></li-->
          </ul>
        </div>
    </mat-toolbar>
    <router-outlet></router-outlet>  
  </mat-sidenav-content>
  <app-footer></app-footer>
</mat-sidenav-container>
