import { Component, OnInit } from '@angular/core';
import Amplify, { API } from 'aws-amplify';

const apiName = 'miaapi';
const path = '/simplification'; 
const myInit = { // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
        name: 'param',
    },
};

API
  .get(apiName, path, myInit)
  .then(response => {
    // Add your code here
    console.log(response);
  })
  .catch(error => {
    console.log(error.response);
});

@Component({
  selector: 'app-reader',
  templateUrl: './reader.component.html',
  styleUrls: ['./reader.component.scss']
})
export class ReaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    API.get(apiName, path, myInit);
  }



}
