<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
<mat-card class="example-card">
    <mat-card-header>
      <!-- div mat-card-avatar class="example-header-image"></div -->
      <mat-card-title>Grip op desinformatie</mat-card-title>
      <mat-card-subtitle>Automatisch fact-checken van Social Media</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image src="/assets/images/sidnfonds-social.png" alt="SIDN fonds">
    <mat-card-content>
      <p>
        Het project "Fact-check op de social media infodemie" is een van de winnaars van de call "Grip op desinformatie", van het SIDN Fonds. In dit project zullen we, ism experts van Amsterdam UMC en RIVM tools ontwikkelen om mogelijke desinformatie op Social media te herkennen en controleren.
      </p>
     </mat-card-content>
    <mat-card-actions>
      <button mat-button>Meer informatie</button>
      <!-- button mat-button>Deel</button -->
    </mat-card-actions>
  </mat-card>

  <mat-card class="example-card">
    <mat-card-header>
      <!-- div mat-card-avatar class="example-header-image"></div -->
      <mat-card-title>Inclusieve medische informatie</mat-card-title>
      <mat-card-subtitle>Mia wint project voor medische tekst versimpeling</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image src="/assets/images/rvo.jpg" alt="RVO">
    <mat-card-content>
      <p>
        Mia is een van de winnaars van de call "Inclusie en een leven lang leren" van RVO SBIR. In ons project zullen we tools ontwikkelen om automatisch complexe medische informatie, rondom Covid-19, te versimpelen en zo begrijpelijk te maken voor een breed publiek.
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button>Meer informatie</button>
    </mat-card-actions>
  </mat-card>

  <!-- mat-card class="example-card">
    <mat-card-header>
      <-- div mat-card-avatar class="example-header-image"></div ->
      <mat-card-title>Shiba Inu</mat-card-title>
      <mat-card-subtitle>Dog Breed</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu">
    <mat-card-content>
      <p>
        The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
        A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
        bred for hunting.
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button>LIKE</button>
      <button mat-button>SHARE</button>
    </mat-card-actions>
  </mat-card -->

</div>
