<mat-tab-group class="unicorn-dark-theme"  fxFlex="100">
  <mat-tab label="Tweets" class="unicorn-dark-theme" style="background-color:black;" fxFlex="100">
    <div fxLayout="column" fxLayoutAlign="space-evenly" fxLayoutGap="10" fxFlex="100">
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-evenly">
          <div fxLayout="column"  fxFlex="50%" ><iframe [src]="url" style="height:500px; width:100%;"></iframe></div>
          <div fxLayout="column"  fxFlex="50%">

            <mat-card>
              <mat-card-content>
                <div fxLayout="column">

                    <h2 class="example-h2">Wat vind je van deze tweet?</h2>

                    <mat-expansion-panel *ngIf="this.currentTweet">
                      <mat-expansion-panel-header [collapsedHeight]="'130px'" [expandedHeight]="'130px'">
                        <mat-panel-title>
                          Is deze tweet relevant voor het thema "vaccinatie"?
                        </mat-panel-title>
                        <mat-panel-description (click)="$event.stopPropagation();">
                          <mat-radio-group aria-label="Is this tweet relevant?" [(ngModel)]="this.currentTweet.relevant" (change)="onItemChange($event, 'relevant')" (click)="$event.stopPropagation();">
                            <mat-radio-button value="1">Ja</mat-radio-button><br>
                            <mat-radio-button value="0">Nee</mat-radio-button>
                          </mat-radio-group>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      We beschouwen een bericht als relevant als:
                      <ul>
                        <li>het bericht expliciet informatie bevat over de vaccinatie of
                        de vaccinatiestrategie</li>
                        <li>het bericht op zichzelf te interpreteren valt/het duidelijk is
                        waar het bericht over gaat</li>
                      </ul>
                        Let op, extra regels om een bericht als niet relevant te
                        beschouwen:
                        <ul>
                          <li>het bericht is volledig in een andere taal geschreven (bijv.
                        Engels)</li>
                        <li>het bericht heeft betrekking op de vaccinatiestrategie in
                        een ander land, en <ol><li>> bevat geen informatie over de
                        vaccinaties die we in Nederland gebruiken (bijv.
                        veiligheid/effectiviteit van Sputnic), of</li>
                        <li>de
                        vaccinatiestrategie in Nederland (bijv. een vergelijking
                        tussen campagne Engeland en Nederland)</li></ol></li>
                        <li>Het bericht is duidelijk Belgisch (door woorden als ‘Amai’,
                        ‘VRT’ en ‘knuffelcontact’ en heeft geen relevantie voor de
                        keuze van Nederlanders voor/tegen vaccineren of de
                        Nederlandse vaccinatiestrategie</li>
                      </ul>
                    </mat-expansion-panel>
                     
                    <div [hidden]="this.currentTweet?.relevant != 1">  
                    <mat-expansion-panel>
                      <mat-expansion-panel-header  [collapsedHeight]="'190px'" [expandedHeight]="'190px'">
                        <mat-panel-title>
                        Wat is het sentiment van deze tweet?<br><br><br>
                        </mat-panel-title>
                        <mat-panel-description  (click)="$event.stopPropagation();">
                          <mat-radio-group aria-label="Sentiment"  [(ngModel)]="this.currentTweet.sentiment" (change)="onItemChange($event, 'sentiment')" >
                            <mat-radio-button value="1">Negatief</mat-radio-button><br>
                            <mat-radio-button value="2">Neutraal</mat-radio-button><br>
                            <mat-radio-button value="0">Positief</mat-radio-button><br>
                          </mat-radio-group>
      
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <b>Negatief:</b> 
                      <p>We beschouwen berichten als negatief wanneer de
                          toon van het bericht expliciet negatief is met betrekking tot</p>
                          <ul>
                          <li>Vaccineren tegen het coronavirus / de vaccin(s) tegen het
                          coronavirus</li>
                          <li>De vaccinatiestrategie/ beleid/ organisatie / logistiek /
                          cijfers</li></ul>

                          <b>Positief:</b> 
                          <p>We beschouwen berichten als positief
                            wanneer de toon van het bericht expliciet positief is met
                            betrekking tot:</p>
                              <ul>
                              <li>Vaccineren tegen het coronavirus / de vaccin(s) tegen het
                              coronavirus</li>
                              <li>De vaccinatiestrategie/ beleid/ organisatie / logistiek /
                              cijfers</li></ul>

                              <b>Neutraal:</b> 
                              <p>We beschouwen een bericht als neutraal, wanneer
                                het niet expliciet negatief of positief is / wanneer we twijfelen /
                                wanneer het bericht ambigu of multi-interpretabel is.</p>
                    </mat-expansion-panel>
                    
                    <mat-expansion-panel>
                      <mat-expansion-panel-header  [collapsedHeight]="'250px'" [expandedHeight]="'250px'">
                        <mat-panel-title>
                          Wat is het onderwerp van deze tweet?
                        </mat-panel-title>
                        <mat-panel-description (click)="$event.stopPropagation();">
                          <section class="example-section" [formGroup]="topics">
                            <mat-checkbox formControlName="veiligheid">veiligheid</mat-checkbox><br>
                            <mat-checkbox formControlName="effectiviteit">effectiviteit</mat-checkbox><br>
                           <mat-checkbox formControlName="noodzaak">noodzaak</mat-checkbox><br>
                            <mat-checkbox formControlName="wantrouwen_complot">wantrouwen/complot</mat-checkbox><br>
                          <mat-checkbox formControlName="anders">anders</mat-checkbox>
                          </section>  
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                        Er zijn zes veelvoorkomende thema’s te identificeren in de
                        negatieve berichten. Dit zijn de thema’s ‘veiligheid’,
                        ‘effectiviteit’, ‘noodzaak’, en ‘wantrouwen/complot’. De
                        berichten die niet in een van deze thema’s vallen komen in de
                        categorie ‘anders’. Dit geldt ook voor alle berichten die (alleen)
                        over de vaccinatiestrategie/beleid/logistiek gaan. Hieronder
                        volgt een beschrijving van ieder thema.

                        <b>Veiligheid</b>
                        <p>Hieronder vallen alle berichten die over de veiligheid van de

                          vaccinatie(s gaan.
                          Coderen als het gaat over:
                          - Veiligheid
                          - (Lange of korte termijn) bijwerkingen
                          - Doden/overlijden na/door vaccinatie
                          - Experimenteel
                          - Proefkonijn
                          - Cijfers over overlijdens na/door vaccinatie
                          
                          Let op:
                          - Wanneer een bericht rapporteert over een corona uitbraak
                          nadat mensen zijn gevaccineerd: zowel als veiligheid als
                          effectiviteit coderen
                          - Niet coderen als het alleen gaat over oorlogsmisdadigers,
                          genocide, moord etc., dan als wantrouwen/complot
                          coderen</p>

                          <b>Effectiviteit</b>
                        <p>Hieronder vallen alle berichten die gaan over de
                          effectiviteit/werkzaamheid van de vaccinatie(s)
                          Let op:
                          de effectiviteit kan betrekking hebben op:
                          - de werkzaamheid om ziekte te voorkomen.
                          - een algemene stelling over de werkzaamheid bevatten (bij.
                          ‘vaccinaties werken niet’).
                          - de mogelijkheid om anderen te besmetten na vaccinatie
                          Wanneer een bericht rapporteert over een corona uitbraak
                          nadat mensen zijn gevaccineerd: zowel als veiligheid als
                          effectiviteit coderen
                          Ook coderen als effectiviteit wanneer er wordt aangegeven dat
                          1 dosis van het vaccin volstaat</p>

                          <b>Noodzaak</b>
                        <p>Hieronder vallen berichten die gaan over de noodzaak van
                          vaccineren tegen corona, met oog op bijvoorbeeld de ernst en
                          
                          besmettelijkheid van COVID-19. Hieronder vallen ook alle
                          berichten die aangeven dat er andere middelen of strategieën
                          zijn die beter zouden zijn tegen het coronavirus dan
                          vaccineren. Voorbeelden zijn medicijnen, gezond eten en
                          natuurlijke immuniteit.</p>

                          <b>Wantrouwen/complot</b>
                          <p>Hieronder vallen alle berichten die met extreme bewoordingen
                            wantrouwen uiten naar vaccinaties tegen het coronavirus, of
                            verwijzingen maken naar complottheorieën.
                            Voorbeelden van extreme bewoordingen of verwijzingen naar
                            complottheorieën zijn:
                            - Experimenteel (ook als veiligheid coderen)
                            - Proefkonijn (ook als veiligheid coderen)
                            - Oorlogsmisdadigers
                            - Genocide
                            - Moord / doodslag
                            - Gentherapie
                            - Operating system
                            - Genetisch gemodificeerd
                            - Bill Gates
                            - The Great Reset
                            - Chip
                            - Leugens
                            - Misdaad
                            - vaccin om je DNA te veranderen
                            - dood spuiten met vaccin /vaccin is gif
                            - we worden voorgelogen, voor de gek gehouden</p>

                            <b>Anders</b>
                            <p>Hierin vallen alle berichten die niet expliciet onder een van de
                              andere thema’s vallen. Deze categorie is uitsluitend en kan dus
                              niet samen met een ander thema worden geannoteerd.
                              Hieronder vallen ook alle berichten gaan over:
                              - Het vaccinatiebeleid/ strategie
                              - De organisatie rondom het aanbieden van vaccinaties / de
                              logistiek
                              - Vaccinatiepaspoort/plicht/dwang
                              - Belang van farmaceutische industrie</p>
                    </mat-expansion-panel>
                  </div>
                  <button mat-raised-button  (click)="save()">opslaan</button>
                </div>
              </mat-card-content>
            </mat-card>
            </div>
        </div>
      </div>
    </mat-tab>
  <mat-tab label="Fact Check">
    <div fxLayout="column" fxLayoutAlign="space-evenly" fxLayoutGap="10" fxFlex="100">
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-evenly">
        <div fxLayout="column"  fxFlex="33%" ><iframe [src]="FCTweeturl" style="height:500px; width:100%;"></iframe></div>
        <div fxLayout="column"  fxFlex="33%" ><iframe [src]="factCheckUrl" style="height:500px; width:100%;"></iframe> <br /> <a target="tab" [href]="factCheckUrlToLink">{{ factCheckUrlToLink }}</a>  </div>
        <div fxLayout="column"  fxFlex="33%">

          <mat-card>
            <mat-card-content>
              <div fxLayout="column">
                <h2 class="example-h2">Lees de hiernaast getoonde tweet en factcheck</h2>

    
                      <p>Is factcheck relevant voor de getoonde tweet?</p>
                    <p (click)="$event.stopPropagation();">
                      <mat-radio-group aria-label="Is this tweet relevant?" [(ngModel)]="this.currentClaim.relevant" (change)="onItemChange($event, 'relevant')" (click)="$event.stopPropagation();">
                        <mat-radio-button value="1">Ja</mat-radio-button><br>
                        <mat-radio-button value="0">Nee</mat-radio-button><br>
                        <mat-radio-button value="2">Weet niet zeker</mat-radio-button><br>
                        <mat-radio-button value="3">De tweet bevat geen verifieerbaar feit</mat-radio-button><br>
                      </mat-radio-group><br>
              <button mat-raised-button  (click)="saveFC()">opslaan</button>
            </p>

            </div>
            </mat-card-content>
            </mat-card>
            </div>
            </div>
            </div>

  </mat-tab>
  <!-- mat-tab label="Claim risico">
    <div fxLayout="column" fxLayoutAlign="space-evenly center" fxLayoutGap="10">
      <h1>Lijkt onderstaande claim betrouwbaar? </h1>
      <p>Denk je dat op basis van de hieronder getoonde tweet de informatie daarin betrouwbaar is? Of zou een fact-check noodzakelijk zijn?</p>
      <br>
      <br>

    <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title>{{currentClaim?.username}}</mat-card-title>
          <mat-card-subtitle>{{currentClaim?.description}}</mat-card-subtitle>
          <mat-card-subtitle>{{currentClaim?.date}}</mat-card-subtitle>
          <mat-card-subtitle><a href="https://twitter.com/i/web/status/{{currentClaim?.tweetid}}" target='new'>open twitter</a></mat-card-subtitle>

        </mat-card-header>
        <mat-card-content>
          <p>
            {{currentClaim?.text}}
          </p>
        </mat-card-content>
      </mat-card>
      <br><br>
      <div fxLayout="row" fxLayoutGap="5">
      <button mat-raised-button (click)="vote('Y', 'claim')">Ja</button><span>  </span><button mat-raised-button (click)="vote('N', 'claim')">Nee</button><span>  </span><button mat-raised-button (click)="vote('NC', 'claim')">Er staat geen claim</button>
    
      <button mat-raised-button>opslaan</button>      <button mat-raised-button>overslaan</button></div>

    </div>
  </mat-tab>
  <mat-tab label="Fact check">
    Linken aan fact check
  </mat-tab>
  <mat-tab label="Simplification">
    Testen van versimpelde tekst
  </mat-tab-->
</mat-tab-group>