import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public scroll(element: any) {
    element.scrollIntoView({ behavior: 'smooth' , block: 'nearest', inline: 'start' });
    //window.scrollBy(0, -100); // Nav's height
    //this.scrolled = true;

  }


  
}
