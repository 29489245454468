import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-miademo',
  templateUrl: './miademo.component.html',
  styleUrls: ['./miademo.component.scss']
})
export class MIADemoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript();
  }

  public loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/mia/Meetinstrumentwelcome.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
}

}
