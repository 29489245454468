
<div fxFlex="100%" fxLayout="column" style="height:100%; width:80%;" >
    <div fxFlex="40%">
        <h1> Mia Twitter Factchecker</h1>
        <p>Met behulp van financiering van RVO en SIDN hebben wij een factchecker voor Twitter gerealiseerd.</p>

        <p>Broncode is <a href="https://github.com/miahelpt/mia_twitter_factchecker">hier</a> te vinden</p>
        <p>Live demo volgt</p>
    </div>
    <!-- mat-card fxFlex="60%">
        <mat-card-content fxFlex="80%">            
            <p>Voer hieronder de tekst van een tweet in</p>
            <textarea width="50%" height="50%"></textarea>
        </mat-card-content>
    </mat-card -->
</div>